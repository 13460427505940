/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcedureSimpleViewModel } from './procedureSimpleViewModel';

export interface AppliedPaymentViewModel {
  visitId?: string;
  visitNumber?: string | null;
  serviceDate?: string | null;
  amountPaid?: number | null;
  visitBalance?: number | null;
  visitProcedures?: Array<ProcedureSimpleViewModel> | null;
}
